<template>
  <div>
    <v-page-title title="Billing" />
    <v-card max-width="1200px" class="mx-auto" elevation="0" color="transparent">
      <v-container>
        <v-row dense align="center">
          <v-col>
            <v-select
              v-model="site"
              :items="sites"
              item-value="url"
              item-text="name"
              hide-details
              dense
              outlined
              label="Select Client"
              @change="getPages"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col>
            <v-loader v-if="!pages"></v-loader>
            <v-data-table
              v-else
              :headers="headers" 
              :items="pages" 
              :items-per-page=20
              class="elevation-1"
            >
              <template v-slot:item.check="{}">
                <v-checkbox
                  color="primary"
                  hide-details></v-checkbox>
              </template>
              <template v-slot:item.url="{item}">
                {{item.url}}
              </template>
              <template v-slot:item.text="{item}">
                {{item.text}}
              </template>
              <template v-slot:item.status="{item}">
                {{item.status}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import extractDomain from 'extract-domain'

  export default {
    name: 'Spelling.vue',
    data() {
      return {
        site: null,
        headers: [
          { text: "", value: "check", width: '60px', divider: true, align: 'center' },
          { text: "Page", value: "url", divider: true},
          { text: "Content", value: "text", divider: true},
          { text: "Status", value: "status", align: 'center', width: '100px' },
        ],
      }
    },
    computed: {
      sites(){
        return this.$store.getters['sites/all'];
      },
      pages(){
        return this.$store.getters['scans/pages'];
      },
    },
    async created(){

    },
    methods:{
      async getPages(){
        await this.$store.dispatch("scans/pages", {
          url: extractDomain(this.site)
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>